.employee-preview-section--inner {
  max-width: 1260px;
  margin: auto;
  overflow: hidden;
}

.employee-preview-top-container {
  display: flex;
  align-items: center;
  margin-top: 40px;
  justify-content: space-between;
  /* margin-left: 90px; */
}

.employee-preview-top--left-wrapper {
  display: flex;
  align-items: center;
  /* margin-left: 60px; */
}

.edit-employee-info-button-wrapper,
.update-employee-info-button-wrapper,
.delete-employee-btn-wrapper {
  display: flex;
  align-items: center;
}

.update-employee-info-button-wrapper button,
.edit-employee-info-button-wrapper button {
  margin-left: 10px;
}

.edit-employee-info-button-wrapper button,
.update-employee-info-button-wrapper button,
.delete-employee-btn-wrapper button {
  width: 250px;
  height: 50px;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 15px;
  line-height: 15px;
}

.edit-employee-info-button-wrapper button {
  background: #ede9f7;
  color: #3a0ca3;
}

.update-employee-info-button-wrapper button {
  background-color: #3a0ca3;
  color: #ffffff;
}

.delete-employee-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 55px;
}

.delete-employee-btn-wrapper button {
  background: #ce2e2e;
  color: #ffffff;
}

/* employee-preview-main-section-container */
.employee-preview-main-section-container {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  column-gap: 50px;
}

.employee-preview-main--left-container {
  width: 400px;
  flex-shrink: 0;
  min-height: 200px;
  height: min-content;
  background: #fff;
  border-radius: 5px;
  padding: 15px 45px 24px;
  /* margin-left: 80px; */
}

.employee-preview-main--right-container {
  width: 100%;
  height: min-content;
}

.employee-details--preview-wrapper {
  width: 100%;
  background: #fff;
  border-radius: 5px;
  padding: 15px 40px 24px;
  flex-shrink: 1;
}

.employee-preview--employee-role--top-left {
  color: rgba(0, 0, 00, 0.7);
  margin-top: 5px;
  font-size: 15px;
  font-family: "moderat-medium";
}

.employee-preview--employee-profile-img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 30px;
}

.employee-preview--employee-profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.employee-preview--employee-name,
.employee-preview--employee-loan-cap--value {
  font-family: "moderat-medium";
  font-size: 20px;
  line-height: 24px;
  color: #2e2e2e;
}

.employee-preview--employee-name {
  margin-top: 25px;
}

.employee-preview--employee-loan-cap {
  margin-top: 50px;
}

.employee-preview--employee-details--row-one {
  width: 100%;
  max-width: 900px;
  margin-top: 45px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 40px;
}

.employee-preview--employee-detail-wrapper {
  max-width: 150px;
}

/* EMPLOYEE EDIT FORM SECTION */
/* Form Boy Wrapper */
.employee-edit-form-main-section-container {
  /* margin-left: 50px;
  margin-right: 50px; */
}
.employee-edit-form-main-section-container .form-body-wrapper {
  /* display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr)); */
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 30px;
  max-width: 650px;
}

.employee-edit-form-main-section-container .form-body-wrapper .form-group {
  /* grid-column: span 6 / span 6; */
}

.employee-edit-form-main-section-container .form-body-wrapper .form-group input,
.employee-edit-form-main-section-container
  .form-body-wrapper
  .form-group
  select,
.employee-edit-form-main-section-container
  .form-body-wrapper
  .form-group
  textarea {
  width: 300px;
}

/* Media queeries */
@media (max-width: 1390px) {
  .employee-edit-form-main-section-container
    .form-body-wrapper
    .form-group
    input,
  .employee-edit-form-main-section-container
    .form-body-wrapper
    .form-group
    select,
  .employee-edit-form-main-section-container
    .form-body-wrapper
    .form-group
    textarea {
    width: 400px;
  }
}

@media (max-width: 1320px) {
  .employee-preview-main--left-container {
    /* margin-left: 50px; */
  }

  .employee-preview-main--right-container {
    /* margin-right: 100px; */
  }

  /* Form Boy Wrapper */
  .employee-edit-form-main-section-container {
    margin-right: 20px;
  }

  .employee-preview--employee-details--row-one {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 30px;
  }
}

@media (max-width: 1140px) {
  .employee-preview-main-section-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
    gap: 50px;
  }
  .employee-preview-main--left-container {
    margin-left: 20px;
  }

  .employee-preview-main--right-container {
    /* margin-left: 40px;
    margin-right: 50px; */
  }

  /* Form Boy Wrapper */
  .employee-edit-form-main-section-container {
    margin-left: 40px;
    margin-right: 10px;
  }
}
