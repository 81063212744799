.requests-container {
  position: relative;
  padding-bottom: 30px;
  font-family: "moderat-regular";
  overflow: hidden;
}

.requests-container--inner {
  max-width: 1260px;
  margin: auto;
  overflow: hidden;
}

.requests-top-wrapper {
  margin-top: 30px;
}

.requests-filter-container {
  position: relative;
  border-bottom: 1px solid #a9a9bc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.requests-filter-option {
  position: relative;
  width: 180px;
  padding: 12px 0;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  /* font-size: 16px; */
  line-height: 20px;
  color: #a9a9bc;
}

.filter-option--salary-advance {
  margin-left: 50px;
}

.active-filter-option {
  color: #1a0404;
}

.salary-advance-table-section,
.bnpl-table-section,
.on-demand-table-section {
  margin-top: 40px;
}

/* Media queeries */

@media (max-width: 1320px) {
  .requests-container--inner {
    padding: 0 25px;
  }
}
