.celebration-type-main-section .primary-back-button-section {
  padding-top: 0;
}

/* credit-wallet-form-wrapper */
.credit-wallet-form-wrapper {
  margin-top: 40px;
}

.celebartion--select-employees-form-section {
  margin-bottom: 40px;
}

.credit-wallet-form-wrapper form {
  max-width: 850px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 380px);
  justify-content: space-between;
  column-gap: 40px;
  row-gap: 45px;
}

.credit-wallet-form-wrapper .form-group {
  /* Preventing focus fade on edges */
  padding: 0 1px 1px;
}

.credit-wallet-form-wrapper .form-group select,
.credit-wallet-form-wrapper .form-group input {
  width: 400px;
}

/* Media Queries */
@media (max-width: 1200px) {
  /* send-message-form-wrapper */
  .credit-wallet-form-wrapper form {
    grid-template-columns: repeat(auto-fit, 330px);
  }

  .credit-wallet-form-wrapper .form-group select,
  .credit-wallet-form-wrapper .form-group input {
    width: 350px;
  }
}
