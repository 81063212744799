.recent-wallet-transactions-container {
  margin-top: 48px;
}

.recent-wallet-transactions-container .section-title {
  font-family: "moderat-semibold";
  line-height: 19px;
  color: #505050;
  margin-bottom: 28px;
}

.credit-type-wrapper {
  color: #008505;
}

.debit-type-wrapper {
  color: #b3261e;
}
