.subscription-summary-container {
  margin-top: 40px;
}

.subscription-summary-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  margin-top: 20px;
}

.single-summary-row {
  max-width: 497px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single-summary-row .summary-title,
.single-summary-row .summary-value {
  font-family: "moderat-medium";
  line-height: 150%;
  color: #1a0404;
}

.next-billing-date-wrapper {
  font-family: "moderat-medium";
  font-size: 14px;
  line-height: 150%;
  color: #3d3d42;
  margin-top: 20px;
}
