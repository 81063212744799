.account-verification-container {
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: #f8f8fa;
}

.account-verification-wrapper {
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 240px;
  padding-bottom: 20px;
}

.verification-message-wrapper .error-handler-container,
.verification-message-wrapper .success-handler-container {
  top: 100px;
}

/* Section Header */
.dashboard-awaiting-data-section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
}

.go-to-login-btn-container {
  margin-top: 10px;
}
.go-to-login-btn-container button {
  width: 150px;
}
