.form-group {
  position: relative;
  width: max-content;
}

.select-dropdown-wrapper {
  width: max-content;
  border-radius: 10px;
}

.form-group select {
  position: relative;
  height: 50px;
  padding: 0 15px;
  border: 1px solid #c0bace;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 18px;
  color: #1a0404;
  background-color: transparent;
  z-index: 3;
}

.form-group .dropdown-icon img {
  position: absolute;
  height: 24px;
  top: 30px;
  right: 20px;
  filter: invert(1);
  z-index: 1;
}
