.single-upload-container {
  padding-bottom: 30px;
  font-family: "moderat-regular";
  position: relative;
  overflow: hidden;
}

.single-upload-container--inner {
  position: relative;
  max-width: 900px;
  margin: auto;
  padding-top: 40px;
}

.single-upload-sub-header {
  margin-top: 10px;
  margin-left: 80px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #a9a9bc;
}

section.single-upload-container-main-section {
}

/* Instrument Ranges setup container */
.single-upload-container-main-section form {
  margin-bottom: 40px;
  margin-left: 80px;
  margin-top: 40px;
}

/* .single-employee-form-row  */
.single-employee-form-row {
  margin-bottom: 40px;
}

.single-employee-form-row .form-header-wrapper {
  width: 460px;
  height: 27px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  background-color: #fff;
  font-size: 14px;
  line-height: 14px;
  color: #3a0ca3;
}

/* Form Boy Wrapper */
.single-employee-form-row .form-body-wrapper {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 40px;
  row-gap: 30px;
  max-width: 650px;
}

.single-employee-form-row .form-body-wrapper .form-group {
  grid-column: span 6 / span 6;
}

.single-employee-form-row .form-body-wrapper .form-group input,
.single-employee-form-row .form-body-wrapper .form-group select,
.single-employee-form-row .form-body-wrapper .form-group textarea {
  width: 300px;
}

.employee-address-form-group textarea {
  height: 120px;
  padding: 5px 15px;
  border: 1px solid #c0bace;
  box-sizing: border-box;
  border-radius: 10px;
  /* text styles  */
  font-size: 15px;
  line-height: 25px;
  /* font-family: "moderat-regular"; */
  font-weight: 400;
  color: #1a0404;
}

/* single-employee-preview-row */
.single-employee-preview-row {
  max-width: 640px;
  min-height: 307px;
  background: #f8f8fa;
  border-radius: 10px;
  margin-bottom: 40px;
  padding: 15px 13px 15px 25px;
}

.single-employee-preview-row--top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.single-employee-preview-title-wrapper,
.employee-info-title-wrapper {
  font-size: 14px;
  line-height: 14px;
  color: #a9a9bc;
}

.single-employee-preview--edit-employee-btn-wrapper button {
  width: 99px;
  height: 33px;
  background-color: #ede9f7;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 13px;
  line-height: 13px;
  font-family: "moderat-regular";
  color: #2e2e2e;
}

.single-employee-preview-row--main-section {
  max-width: 300px;
  width: 100%;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 50px;
  row-gap: 20px;
}

.single-employee-preview--info {
  grid-column: span 6 / span 6;
  max-width: 215px;
}

.employee-info-value-wrapper {
  font-size: 14px;
  line-height: 12px;
  color: #2e2e2e;
  margin-top: 8px;
}

/* .staff-onboarding-handler-wrapper .error-handler-container {
  top: 0;
} */

.add-new-employee-plus-btn-wrapper button {
  background-color: #fff !important;
}
