.onboarding-preview-container {
  padding-bottom: 50px;
  font-family: "moderat-regular";
}

.onboarding-preview-container--inner {
  max-width: 1060px;
  margin: auto;
  overflow: hidden;
  padding-top: 50px;
}

.onboarding-preview-top-container {
  margin-left: 90px;
  display: flex;
  align-items: center;
}

.onboarding-preview-top--left-wrapper {
  display: flex;
  align-items: center;
  margin-left: 50px;
}

.onboarding-preview-top-action-buttons-container {
  display: flex;
  align-items: center;
}

.decline-request-button-wrapper,
.approve-request-button-wrapper {
}

.decline-request-button-wrapper button,
.approve-request-button-wrapper button,
.request-status-message-wrapper {
  width: 220px;
  height: 50px;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.decline-request-button-wrapper button {
  background: #ce2e2e;
  margin-right: 20px;
}

.approve-request-button-wrapper button {
  background: #3a0ca3;
}

.decline-request-button-wrapper button img,
.approve-request-button-wrapper button img {
  margin-right: 5px;
}

.request-status-message-wrapper {
  cursor: default;
}

.request-declined-status-wrapper {
  background: #ce2e2e;
}

.request-approved-status-wrapper {
  background: #00ba07;
}

/* onboarding-preview-main-container */
.onboarding-preview-main-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.onboarding-preview-main-container--inner {
  min-height: 395px !important;
}

.onboarding-preview--employee-details--row-one {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

/* Salary and group form wrapper  */
.salary-and-group-form-group-wrapper {
  width: 609px;
  height: 80px;
  background: #ffffff;
  border-radius: 5px;
  margin-left: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.salary-and-group-form-group-wrapper .form-group input,
.salary-and-group-form-group-wrapper .form-group select {
  width: 260px;
  height: 40px;
}

.salary-and-group-form-group-wrapper .form-group .dropdown-icon img {
  top: 28px;
  right: 16px;
}
