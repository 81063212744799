.credit-employee-summary-container {
  margin-top: 24px;
}

.summary-details-title {
  font-family: "moderat-medium";
  font-size: 14px;
  line-height: 150%;
  color: #3d3d42;
}

.summary-detail-container--inner {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-top: 20px;
}

.summary-detail-wrapper {
  display: flex;
  column-gap: 20px;
  font-family: "moderat-medium";
  line-height: 150%;
  color: #1a0404;
}

.summary-detail-key {
  width: 250px;
}

.summary-detail-value {
  display: flex;
  justify-content: flex-end;
  width: 300px;
  text-align: right;
}

.payment-summary-details-container {
  margin-top: 40px;
}

.grouped-payment-detail-container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
