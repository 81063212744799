.onboard-employees-container {
  padding-bottom: 30px;
  font-family: "moderat-regular";
  overflow: hidden;
}

.onboard-employees-container--inner {
  max-width: 900px;
  margin: auto;
  padding-top: 40px;
  /* min-height: 50vh; */
}

.onboard-employees-top-container {
  display: flex;
  align-items: center;
}

.onboard-employees-top-container .back-button-wrapper button {
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
}

/* onboard-employees-container-main-section */
section.onboard-employees-container-main-section {
  margin-top: 70px;
}

.onboard-employees-action-buttons {
  display: flex;
  align-items: center;
}

.onboard-employees-action-button-wrapper:nth-last-child(1) {
  margin-left: 30px;
}

.onboard-employees-action-button-wrapper button {
  width: 378px;
  height: 68px;
  background: #fff;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "moderat-medium";
  font-weight: 500;
  font-size: 17px;
  line-height: 18px;
  color: #2e2e2e;
}
