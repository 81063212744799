.data-awaiting-page-wrapper {
  background-color: white;
}

/* Dashboard Awaiting data Section */
section.dashboard-awaiting-data-section {
  background-color: white;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 20px;
}

/* Section Header */
.dashboard-awaiting-data-section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
}

.dashboard-awaiting-data-section-header-hero-img-wrapper {
  /* transform: translateY(); */
}

.dashboard-awaiting-data-section-header-title {
  max-width: 750px;
  font-family: "moderat-bold";
  font-size: 40px;
  line-height: 54px;
  margin: 5px 0 10px;
  text-align: center;
  background: linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  display: flex;
  align-items: center;
}

.dashboard-awaiting-data-section-header-title img {
  transform: translateY(4px);
  margin-left: 10px;
}

.dashboard-awaiting-data-section-header-sub-title {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #2e2e2e;
  text-align: center;
}

/* Media Queries */
@media (max-width: 520px) {
  .dashboard-awaiting-data-section-header-title {
    font-size: 30px;
    line-height: 30px;
  }
}
