.employees-container {
  padding-bottom: 50px;
  font-family: "moderat-regular";
  font-weight: 400;
  position: relative;
  overflow: hidden;
}

.employees-container--inner {
  max-width: 1260px;
  margin: auto;
  overflow: hidden;
}

/* all-company-groups-section-container */
section.all-company-groups-section-container {
  margin-top: 40px;
}

.employees-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-new-group-button-wrapper {
  display: flex;
  align-items: center;
}

.create-new-group-button-wrapper button {
  width: 220px;
  height: 50px;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  background: #3a0ca3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

/* all-groups-main-container */
.all-groups-main-container {
  margin-top: 50px;
  padding: 0 50px;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 50px;
  row-gap: 50px;
}

/* company-group-wrapper */
.company-group-wrapper {
  grid-column: span 4 / span 4;
}

.company-group-wrapper a {
  width: 300px;
  height: 200px;
  /* background: #f8f8fa; */
  background: #fff;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 25px;
  text-decoration: none;
}

.user-icon-wrapper {
  width: 50px;
  height: 50px;
  background: #ede9f7;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.group-name-wrapper {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #2e2e2e;
  margin-top: 25px;
}

.no-of-employees-in-group-wrapper {
  display: flex;
  margin-top: 30px;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #2e2e2e;
}

.three--employees-profile-img-wrapper {
  display: flex;
  margin-right: 5px;
}

.three--employees-profile-img {
  width: 18px;
  height: 18px;
  border: 1px solid #3a0ca3;
  border-radius: 50%;
  overflow: hidden;
}

.three--employees-profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.three--employees-profile-img:nth-child(1) {
  z-index: 5;
}
.three--employees-profile-img:nth-child(2) {
  z-index: 10;
  transform: translateX(-5px);
}
.three--employees-profile-img:nth-child(3) {
  z-index: 15;
  transform: translateX(-10px);
}

.employees--actions-handler-wrapper .success-handler-container,
.employees--actions-handler-wrapper .error-handler-container {
  top: 0px !important;
}

.employees-loader-wrapper {
  margin-top: 10px;
}

/* Media queeries */
@media (max-width: 1320px) {
  .employees-container--inner {
    padding: 0 25px;
  }

  .company-group-wrapper {
    grid-column: span 6 / span 6;
  }
}

@media (max-width: 1210px) {
  /* all-groups-main-container */
  .all-groups-main-container {
    padding: 0 40px;
    column-gap: 40px;
    row-gap: 50px;
  }
}

@media (max-width: 1034px) {
  /* all-groups-main-container */
  .all-groups-main-container {
    padding: 0;
    column-gap: 40px;
    row-gap: 50px;
  }

  .company-group-wrapper a {
    width: 280px;
    height: 200px;
  }
}
