.welfare-container {
  position: relative;
  padding-bottom: 50px;
  overflow: hidden;
}

.welfare-container--inner {
  max-width: 1260px;
  margin: auto;
  overflow: hidden;
}

.welfare-table-section {
  margin-top: 40px;
}

.welfare-table--top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2px;
}

.welfare-top-action-button-wrapper {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

/* welfare-table-filter-option-wrapper */
.welfare-table-filter-option-wrapper {
  display: flex;
  align-items: center;
}

.welfare-table-filter-row-title {
  font-family: "moderat-medium";
  line-height: 15px;
  color: #a9a9bc;
  margin-right: 10px;
}

.welfare-table-filter-option-wrapper .form-group {
  margin-left: 20px;
}

.welfare-table-filter-option-wrapper .select-dropdown-wrapper {
  background-color: #fff;
}

.welfare-table-filter-option-wrapper .form-group select {
  width: 150px;
  border: none;
  color: #dadada;
  background-color: transparent !important;
  position: relative;
  z-index: 4;
}

.welfare-table-filter-option-wrapper .form-group:nth-child(2) select {
  width: 200px;
}

.welfare-table-filter-option-wrapper select option {
  font-size: 14px;
  color: #0d0520;
  z-index: 1;
}

.welfare-table-filter-option-wrapper .form-group .dropdown-icon img {
  top: 35%;
}

.welfare--title-wrapper {
  font-weight: 700;
  line-height: 150%;
  color: #2e2e2e;
}

.welfare--title-wrapper span {
  color: #707070;
}

.request-awaiting-approval-wrapper {
  color: #b5179e !important;
}

.request-rejected-wrapper {
  color: #ecbe1c !important;
}

.request-completed-wrapper {
  color: #00ba07 !important;
}
