.credit-amount-form-wrapper {
  max-width: 840px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 400px);
  column-gap: 32px;
  row-gap: 32px;
}

.credit-amount-form-wrapper .form-group {
  /* Preventing focus fade on edges */
  padding: 0 1px 1px;
}

.credit-amount-form-wrapper .form-group select,
.credit-amount-form-wrapper .form-group input {
  width: 400px;
}
