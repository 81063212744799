.modal_section {
  display: none;
}

.modal_section.show {
  display: block;
}

.modal_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 60;
}

.overlay_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.modal_content_container {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 395px;
  min-height: 200px;
  z-index: 50;
  background: #ffffff;
  border-radius: 20px;
  padding: 25px 35px;
}

.fund_wallet_modal_header {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
  width: 100%;
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
  color: #3a0ca3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form_container {
  width: 100%;
  margin-top: 30px;
}

.form_container input {
  width: 100%;
}

.cta_container {
  margin-top: 45px;
}

.make_withdrawal_button_wrapper {
  display: flex;
  align-items: center;
}

.make_withdrawal_button_wrapper button {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  background-color: #3a0ca3;
  color: #ffffff;
}
