.welfare-nav-section {
  margin-top: 30px;
}

.welfare-filter-container {
  position: relative;
  border-bottom: 1px solid #a9a9bc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welfare-filter-option {
  position: relative;
  width: 180px;
  padding: 12px 0;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  /* font-size: 16px; */
  line-height: 20px;
  color: #a9a9bc;
}
