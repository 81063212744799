.subscription-plan-container {
  padding-bottom: 50px;
  font-family: "moderat-regular";
  font-weight: 400;
  position: relative;
  overflow: hidden;
}

.subscription-plan-container--inner {
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  min-height: 100vh;
  margin-top: 80px;
}

.subscription-plan--top-wrapper {
  padding-top: 40px;
}

.subscription-plan-choices-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px 36px;
  margin-top: 42px;
}

.subscription-plan-choice-wrapper {
  position: relative;
  width: 372px;
  min-height: 515px;
  border: 2px solid #d9d9d9;
  border-radius: 8px;
  padding: 36px;
  transition: all 0.3s ease-in-out;
}

.focused-plan {
  border: 2px solid #3a0ca3;
}

/* .recommended-wrapper */
.recommended-wrapper {
  position: absolute;
  top: -0.2px;
  left: -0.2px;
  width: 100.2%;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #aeaeb7;
  border-radius: 6px 6px 0px 0px;
  /* Text */
  font-family: "moderat-medium";
  font-size: 13px;
  color: #ffffff;
}

.plan-title {
  font-family: "moderat-bold";
  font-size: 24px;
  line-height: 130%;
  color: #1a0404;
}

.plan-price {
  font-family: "moderat-medium";
  font-size: 18px;
  line-height: 150%;
  color: #3d3d42;
  margin-top: 4px;
}

.subscribe-btn-wrapper {
  margin-top: 26px;
}

.subscribe-btn-wrapper button {
  width: 250px;
  height: 40px;
  font-size: 14px;
}

.plan-perks-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border-top: 1px solid #d9d9d9;
  margin-top: 32px;
  padding-top: 32px;
}

.plan-perk {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-family: "moderat-medium";
  line-height: 150%;
  color: #3d3d42;
}

/* Media queries */
@media (max-width: 1410px) {
  .subscription-plan-choices-container {
    gap: 40px 30px;
  }

  .subscription-plan-choice-wrapper {
    padding: 36px 30px;
  }
}

@media (max-width: 1360px) {
  .subscription-plan-container--inner {
    padding: 0 25px;
  }

  .subscription-plan-choices-container {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 1320px) {
  .subscription-plan-container--inner {
    padding: 0 25px;
  }
}
