.api-secret-key-form-group {
  position: relative;
  margin-top: 25px;
}

.api-secret-key-form-group input {
  width: 400px;
}

.secret-key-copy-btn-wrapper {
  position: absolute;
  right: -40px;
  top: 30px;
}

.secret-key-copy-btn-wrapper button {
  border: none;
  background-color: inherit;
  padding: 0;
}

.generate-secret-key-btn-container {
  margin-top: 120px;
}

.generate-secret-key-btn-wrapper button {
  width: 240px;
}
