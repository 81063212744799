.credit-all-employees-container {
  position: relative;
  padding-bottom: 50px;
  overflow: hidden;
}

.credit-all-employees-container--inner {
  max-width: 1260px;
  margin: auto;
  overflow: hidden;
}

.voucher-countries-and-categories-container {
  margin-top: 40px;
}

.voucher-countries-and-categories-container .section-title {
  font-family: "moderat-semibold";
  line-height: 19px;
  color: #2e2e2e;
}

.country-checkbox,
.categories-checkbox {
  display: flex;
  align-items: center;
}

.country-checkbox input[type="checkbox"],
.categories-checkbox input[type="checkbox"] {
  width: 18.4px;
  height: 18.4px;
  background: inherit !important;
  outline: none;
  accent-color: #3a0ca3;
}

.credit-staff-wallets-btn-wrapper {
  margin-top: 50px;
}

.credit-staff-wallets-btn-wrapper button {
  width: 250px;
}
