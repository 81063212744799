.invoices-container {
  padding-bottom: 50px;
  font-family: "moderat-regular";
  font-weight: 400;
  position: relative;
  overflow: hidden;
}

.invoices-container--inner {
  max-width: 1260px;
  margin: auto;
  overflow: hidden;
}

/* Media queries */
@media (max-width: 1320px) {
  .invoices-container--inner {
    padding: 0 25px;
  }
}
