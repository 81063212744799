.grouped-employees-container {
  padding-bottom: 50px;
  font-family: "moderat-regular";
  font-weight: 400;
}

.grouped-employees-container--inner {
  max-width: 1260px;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.grouped-employees-header-wrapper {
  display: flex;
  align-items: center;
  margin-top: 40px;
  /* margin-left: 150px; */
}

.grouped-employees-header-wrapper .back-button-wrapper button {
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
}

.grouped-employees-header-title {
  font-weight: 700;
  font-size: 17px;
  line-height: 18px;
  color: #2e2e2e;
  padding-left: 20px;
}

.grouped-employees-header-title span {
  color: #3a0ca3;
}

.add-new-employee-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.add-new-employee-btn-wrapper button {
  width: 250px;
  height: 50px;
  background: #3a0ca3;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
}

/* All Orders Table Container */
.grouped-employees-table-container {
  margin-top: 40px;
}

.grouped-employees-table {
  width: 100%;
  background-color: #f0ecf8;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  /* Remove table gaps and margins */
  border-spacing: 0px;
}

.grouped-employees-table thead {
  background-color: #f0ecf8;
}
/* Table Head , Table Row */
.grouped-employees-table thead tr {
  border-radius: 5px 5px 0px 0px;
}

/* Table Head , Table Row, Thable header*/
.grouped-employees-table thead tr th {
  padding: 20px 10px;
  font-family: "moderat-semibold";
  font-size: 14px;
  line-height: 12px;
  color: #3a0ca3;
}

.grouped-employees-table thead tr th:nth-child(1) {
  padding-left: 50px;
}

/* Table Body */
.grouped-employees-table tbody {
  margin: 0;
}

.grouped-employees-table tbody tr {
  overflow: hidden;
}

.grouped-employees-table tbody tr:hover td {
  background-color: #f8f8fa;
}

/* Table Body , Table Data*/
.grouped-employees-table tbody tr td {
  background-color: #ffffff;
  padding: 16px 10px;
  font-family: "moderat-medium";
  color: #2e2e2e;
  font-size: 15px;
  border-bottom: 1px solid #f3f3f3 !important;
}

.grouped-employees-table tbody tr td:nth-child(1) {
  padding-left: 50px;
}

/*  Name Wrapper */
.grouped-employees-table .td-employee-name--wrapper {
  display: flex;
  align-items: center;
  max-width: 300px;
}

/* .grouped-employees-table .td-employee-name--wrapper .profile-image-wrapper {
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 15px;
}

.grouped-employees-table .td-employee-name--wrapper .profile-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.td-edit-btn--wrapper button {
  width: 31px;
  height: 31px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  background-color: transparent;
}

.overflow-hidden {
  overflow: hidden;
  height: 100vh;
}

/* Media queeries */
@media (max-width: 1320px) {
  .grouped-employees-container--inner {
    padding: 0 25px;
  }
}
