.requests-preview-container {
  padding-bottom: 30px;
  font-family: "moderat-regular";
}

.requests-preview-container--inner {
  max-width: 1060px;
  margin: auto;
  overflow: hidden;
  padding-top: 50px;
}

.requests-preview-top-container {
  /* margin-left: 90px; */
}

.requests-preview-top--left-wrapper {
  display: flex;
  align-items: center;
  /* margin-left: 60px; */
}

.request-pending-msg-display-wrapper,
.request-completed-msg-display-wrapper {
  width: 250px;
  height: 50px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.request-pending-msg-display-wrapper {
  background: rgba(236, 190, 28, 0.1);
  color: #ecbe1c;
}

.request-completed-msg-display-wrapper {
  background: rgba(0, 186, 7, 0.1);
  color: #00ba07;
}

/* Main section wrapper */
.requests-preview-main-section-container {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

/* requests-preview-main-section--left-container */
.requests-preview-main-section--left-container {
}

.requests-preview--employee-details-container {
  width: 511px;
  min-height: 395px;
  background: #fff;
  border-radius: 5px;
  padding: 15px 45px 24px;
}

.requests-preview--employee-email {
  margin-top: 15px;
}

.requests-preview--employee-details--title {
  font-weight: 700;
  font-size: 15px;
  line-height: 14px;
  color: #3a0ca3;
}

.requests-preview--employee-details--row-one {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.requests-preview--employee-profile-img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 40px;
}

.requests-preview--employee-profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.requests-preview--employee-name-email {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #2e2e2e;
}

/* Row Two */
.requests-preview--employee-details--row-two {
  margin-top: 45px;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  column-gap: 100px;
  row-gap: 40px;
}

.requests-preview--employe-detail-wrapper {
  grid-column: span 3 / span 3;
}

.requests-preview--employee-detail--title,
.repayment-date {
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: #a9a9bc;
}

.requests-preview--employee-detail--value {
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #2e2e2e;
  margin-top: 3px;
  word-wrap: break-word;
}

/* Main Right Container */
.requests-preview-main-section--right-container {
  margin-left: 30px;
}

.requests-preview-main-section--right--top-wrapper,
.requests-preview-main-section--right--middle-wrapper,
.requests-preview-main-section--right--bottom-wrapper {
  width: 511px;
  min-height: 100px;
  background: #fff;
  border-radius: 5px;
  padding: 15px 41px 24px;
}

.requests-preview-main--right--top-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

/* request-amount-wrapper */
.request-amount-wrapper {
  width: 100%;
  height: 80px;
  background: #f8f8fa;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 25px;
}

.request-amount-value {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #2e2e2e;
}

.declination-reason-value {
  color: #ff4848;
  font-size: 20px;
}

.other-request-finance--value {
  margin-top: 5px;
}

.other-request-finance-values-wrapper {
  margin-top: 40px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
}

.requests-preview-main-section--right--middle-wrapper,
.requests-preview-main-section--right--bottom-wrapper {
  margin-top: 30px;
}

.vendor-details-wrapper {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.vendor-details--left-wrapper {
  display: flex;
  align-items: center;
}

.vendor-img-wrapper {
  width: 35px;
  height: 35px;
  border: 1px solid #3a0ca3;
  border-radius: 50%;
  overflow: hidden;
}

.vendor-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.shop-name-and-category-wrapper {
  margin-left: 10px;
}

.vendor-shop-name-wrapper {
  font-weight: 500;
  font-size: 15px;
  line-height: 13px;
  color: #2e2e2e;
}

.vendor-shop-category-wrapper {
  font-size: 12px;
  line-height: 15px;
  color: #737373;
  margin-top: 5px;
}

.vendor-ratings-wrapper {
  font-size: 12px;
  line-height: 15px;
  color: #737373;
  display: flex;
  align-items: center;
}

.ratings-value {
  display: flex;
  margin-left: 5px;
}

/* bnpl-repayment-container */
.bnpl-repayment-container {
  margin-top: 20px;
}

.bnpl-repayment-wrapper {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border-radius: 5px;
  padding: 0 30px 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.repayment-value {
  font-weight: 500;
  font-family: "moderat-medium";
  line-height: 18px;
  color: #ce2e2e;
}

.requests-loader-wrapper {
  margin-left: 8px;
}

button:disabled {
  opacity: 0.8;
}
