.settlement-preview-container {
  padding-bottom: 30px;
  font-family: "moderat-regular";
}

.settlement-preview-container--inner {
  max-width: 1060px;
  margin: auto;
  overflow: hidden;
  padding-top: 50px;
}

.settlement-preview-top-container {
  margin-left: 90px;
}

.settlement-preview-top--left-wrapper {
  display: flex;
  align-items: center;
  /* margin-left: 60px; */
}

/* Main section wrapper */
.settlement-preview-main-section-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settlement-preview--employee-details-container {
  width: 984px;
  min-height: 490px;
  background: #f8f8fa;
  border-radius: 5px;
  padding: 15px 45px 24px;
}

.settlement-preview--employee-details--title {
  font-weight: 700;
  font-size: 15px;
  line-height: 14px;
  color: #3a0ca3;
}

.settlement-preview--employee-details--row-one {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.settlement-preview--employee-profile-img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 20px;
}

.settlement-preview--employee-profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.settlement-preview--employee-name {
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: #2e2e2e;
  max-width: 150px;
}

/* Row Two */
.settlement-preview--employee-details--row-two {
  margin-top: 45px;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  column-gap: 100px;
  row-gap: 40px;
}

.settlement-preview--employe-detail-wrapper {
  grid-column: span 2 / span 2;
}

.settlement-preview--employee-detail--title {
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  color: #a9a9bc;
}

.settlement-preview--employee-detail--value {
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #2e2e2e;
  margin-top: 3px;
}

.settlement-preview--employee-details--row-three {
  width: 100%;
  height: 80px;
  background: #ffffff;
  border-radius: 5px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
}

/* settlement-preview--employee-payment-itinerary-container */
.settlement-preview--employee-payment-itinerary-container {
  margin-top: 60px;
  max-width: 948px;
  width: 100%;
}

.payment-itinerary-sort-dropdown-wrapper {
  position: relative;
}

.payment-itinerary-sort-dropdown-wrapper select {
  width: 110px;
  height: 33.33px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  background-color: #f8f8fa;
  border-radius: 10px;
  border: none;
  outline: none;
  box-sizing: border-box;
  font-style: normal;
  /* font-weight: 500; */
  font-size: 13px;
  line-height: 12px;
  color: #dadada;
}

.payment-itinerary-sort-dropdown-wrapper select option {
  font-size: 14px;
  color: #0d0520;
}

.payment-itinerary-sort-dropdown-wrapper .dropdown-icon img {
  width: 19.85px;
  height: 17.17px;
  position: absolute;
  height: 24px;
  top: 6px;
  right: 5px;
  filter: invert(1);
}

.payment-itinerary-table {
  /* width: 984px; */
  width: 100%;
}

.payment-itinerary-table thead tr th:nth-child(1) {
  padding-left: 60px;
}

.payment-itinerary-table tbody tr td:nth-child(1) {
  padding-left: 60px;
}

.status-success-wrapper {
  color: #00ba07;
}

.status-failed-wrapper {
  color: #ff4848;
}

@media (max-width: 1050px) {
  .payment-itinerary-table thead tr th:nth-child(1) {
    padding-left: 30px;
  }

  .payment-itinerary-table tbody tr td:nth-child(1) {
    padding-left: 30px;
  }
}
