.celebration-type-container {
  padding-bottom: 50px;
  font-family: "moderat-regular";
  font-weight: 400;
  position: relative;
  overflow: hidden;
}

.celebration-type-container--inner {
  max-width: 1260px;
  margin: auto;
  overflow: hidden;
  padding: 30px 0 0;
}

.benefits--form-title-wrapper {
  font-family: "moderat-medium";
  font-size: 14px;
  line-height: 150%;
  color: #3d3d42;
}

.celebration-type-form-container {
  margin-top: 36px;
}

/* send-message-form-wrapper */
.send-message-form-wrapper {
  margin-top: 40px;
}

.send-message-form-wrapper form {
  display: flex;
  flex-direction: column;
  row-gap: 45px;
}

.send-message-form-wrapper .form-group {
  /* Preventing focus fade on edges */
  padding: 0 1px 1px;
}

.send-message-form-wrapper .form-group select,
.send-message-form-wrapper .form-group textarea {
  width: 592px;
}

.send-message-form-wrapper .form-group textarea {
  min-height: 172px;
}

.send-celebration-btn-wrapper button {
  width: 250px;
}

/* Media queries */
@media (max-width: 1320px) {
  .celebration-type-container--inner {
    padding: 0 25px;
  }
}
