section.wallet_balance_box_section {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px 30px;
}

.wallet_balance_box_container {
  max-width: 500px;
  width: 100%;
  height: 150px;
  border: 1px solid #ede9f7;
  border-radius: 20px;
  padding: 15px 25px 0px;
}

.box__row_top_wrapper,
.box__row_bottom_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box__row_title_wrapper {
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
  text-transform: uppercase;
  color: #0d0520;
}

.make_withdrawal_button_wrapper button {
  width: 120px;
  height: 43.33px;
  background: #3a0ca3;
  border-radius: 9px;
}

.make_withdrawal_button_wrapper button:hover {
  background: #320a90;
}

.wallet_balance_value_wrapper {
  font-weight: 500;
  font-size: 36px;
  line-height: 38px;
  color: #3a0ca3;
}

/* Media Queries */
@media (min-width: 1400px) {
  .wallet_balance_box_container {
    height: 150px;
  }
}

@media (max-width: 900px) {
  .wallet_balance_box_container {
    max-width: 100%;
  }
}
