.secondary-modal-container {
  position: absolute;
  width: 444px;
  min-height: 289px;
  z-index: 50;
  left: 36%;
  top: 25%;
  background: #ffffff;
  border-radius: 12px;
}

.secondary-modal-container--inner {
  padding: 32px;
  color: #161a1d;
}

.secondary-modal--top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.secondary-modal--title {
  font-family: "moderat-bold";
  font-size: 20px;
}

.secondary-modal--title,
.secondary-modal--sub-title {
  line-height: 20px;
}

.secondary-modal--cancel-btn-wrapper {
  display: flex;
  align-items: flex-end;
}

.secondary-modal--cancel-btn-wrapper button {
  padding: 0;
  border: none;
  background: inherit;
}

.secondary-modal--bottom-wrapper {
  margin-top: 21px;
}

.secondary-modal--sub-title {
  font-size: 14px;
}

/* Modal Options */
.modal-options-container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 16px;
}

.modal-option-wrapper button {
  width: 100%;
  min-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  background: #e8ebed;
  border-radius: 10px;
  border: none;
  text-align: left;
}

.modal-option--left-wrapper {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.modal-option--main-text {
  font-family: "moderat-medium";
  line-height: 20px;
}

.modal-option--sub-text {
  font-size: 12px;
  font-family: "moderat-regular";
  line-height: 20px;
  color: #5b6771;
}

/* Payment Method */
.payment-method-modal-container {
  min-height: 126px;
}
