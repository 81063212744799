/* selected-plan-display-wrapper */
.selected-plan-display-wrapper {
  margin-top: 40px;
}

.subscription-detail-title {
  font-family: "moderat-medium";
  font-size: 14px;
  line-height: 150%;
  color: #3d3d42;
}

.plan-display-box-wrapper {
  width: 477px;
  height: 94px;
  padding: 20px 28px;
  border: 2px solid #d9d9d9;
  border-radius: 8px;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan-display-box-wrapper .left-col {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.selected-plan-name {
  font-family: "moderat-bold";
  font-size: 20px;
  line-height: 100%;
  color: #1a0404;
}

.selected-plan-price {
  font-family: "moderat-medium";
  line-height: 150%;
  color: #3d3d42;
}

.change-plan-btn-wrapper button {
  width: 145px;
  height: 40px;
  font-size: 14px;
}

.continue-to-payment-btn-wrapper {
  margin-top: 60px;
}

.continue-to-payment-btn-wrapper button {
  width: 250px;
  height: 50px;
  font-size: 14px;
}
