.credit-new-wallet-container {
  position: relative;
  padding-bottom: 50px;
  overflow: hidden;
}

.credit-new-wallet-container--inner {
  max-width: 1260px;
  margin: auto;
  overflow: hidden;
}

.credit-new-wallet--top-wrapper {
  padding-top: 40px;
}

/* Media queries */
@media (max-width: 1320px) {
  .credit-new-wallet-container--inner {
    padding: 0 25px;
  }
}
