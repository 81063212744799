.settlements-container {
  position: relative;
  padding-bottom: 30px;
  font-family: "moderat-regular";
  overflow: hidden;
}

.settlements-container--inner {
  max-width: 1260px;
  margin: auto;
  overflow: hidden;
}

.settlements-top-wrapper {
  margin-top: 30px;
}

.settlements-filter-container {
  position: relative;
  border-bottom: 1px solid #a9a9bc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settlements-filter-option {
  position: relative;
  width: 180px;
  padding: 12px 0;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  line-height: 20px;
  color: #a9a9bc;
}

.filter-option--all {
  margin-left: 50px;
}

/* settlements-main-container--row-one */
.settlements-main-container--row-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

/* orders-table-filter-option-wrapper */
.settlements-table-filter-option-wrapper {
  display: flex;
  align-items: center;
}

.settlements-table-filter-row-title {
  font-family: "moderat-medium";
  /* font-size: 15px; */
  line-height: 15px;
  color: #a9a9bc;
  margin-right: 10px;
}

.settlements-table-filter-option-wrapper .form-group {
  margin-left: 20px;
}

.settlements-table-filter-option-wrapper .select-dropdown-wrapper {
  background-color: #f8f8fa;
}

.settlements-table-filter-option-wrapper .form-group select {
  width: 150px;
  background: #f8f8fa;
  border: none;
  color: #dadada;
  background-color: transparent;
  position: relative;
  z-index: 4;
}

.settlements-table-filter-option-wrapper select option {
  font-size: 14px;
  color: #0d0520;
}

.settlements-table-filter-option-wrapper .form-group .dropdown-icon img {
  top: 35%;
  z-index: 1;
}

/* All settlements Table Container */
.settlements-table-container {
  position: relative;
}

.all-settlements-table-container {
  margin-top: 40px;
}

.all-settlements-table {
  width: 100%;
  background-color: #f0ecf8;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  /* Remove table gaps and margins */
  border-spacing: 0px;
}

.all-settlements-table thead {
  background-color: #f0ecf8;
}
/* Table Head , Table Row */
.all-settlements-table thead tr {
  border-radius: 5px 5px 0px 0px;
}

/* Table Head , Table Row, Thable header*/
.all-settlements-table thead tr th {
  padding: 20px 10px;
  font-family: "moderat-semibold";
  font-size: 14px;
  line-height: 12px;
  color: #3a0ca3;
}

.all-settlements-table thead tr th:nth-child(1) {
  padding-left: 55px;
}

/* Table Body */
.all-settlements-table tbody {
  margin: 0;
}

.all-settlements-table tbody tr {
  overflow: hidden;
}

/* Table Body , Table Data*/
.all-settlements-table tbody tr td {
  background-color: #f8f8fa;
  padding: 23px 10px;
  font-family: "moderat-medium";
  color: #2e2e2e;
  font-size: 15px;
  border-bottom: 1px solid #f3f3f3 !important;
  cursor: pointer;
}

.all-settlements-table tbody tr td:nth-child(1) {
  padding-left: 55px;
}

.settlements-pending-wrapper {
  color: #ecbe1c !important;
}

.settlements-completed-wrapper {
  color: #00ba07 !important;
}

.td-pay-settlement--wrapper a,
.td-view-invoice--wrapper button,
.td-pay-settlement--wrapper span {
  align-items: center;
  border: none;
  border-radius: 5px;
  /* cursor: pointer; */
  display: flex;
  font-size: 15px;
  font-weight: 500;
  height: 32px;
  justify-content: center;
  line-height: 15px;
  outline: none;
  width: 120px;
  text-decoration: none;
}

.td-pay-settlement--wrapper a {
  background-color: rgba(58, 12, 163, 0.9);
  color: #fff;
}

.td-view-invoice--wrapper button {
  background: rgba(0, 186, 7, 0.1);
  color: #00ba07;
}

.td-pay-settlement--wrapper span {
  color: #3a0ca3;
  cursor: default;
}

.mark-settlement-loading-spinner-wrapper {
  position: absolute;
  right: 0;
  top: 10px;
}
/*  */
/*  */
/*  */
.none {
  display: none;
}

/* Media queeries */
@media (max-width: 1320px) {
  .settlements-container--inner {
    padding: 0 25px;
  }
}

@media (max-width: 1050px) {
  .all-settlements-table thead tr th:nth-child(1) {
    padding-left: 30px !important;
  }

  .all-settlements-table tbody tr td:nth-child(1) {
    padding-left: 30px !important;
  }
}
