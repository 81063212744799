.credit-selected-employee-container {
  position: relative;
  padding-bottom: 50px;
  overflow: hidden;
}

.credit-selected-employee-container --inner {
  max-width: 1260px;
  margin: auto;
  overflow: hidden;
}

.credit-selected-employee-form-container {
  margin-top: 24px;
}
