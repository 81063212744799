.employee-profile-container {
  position: relative;
  padding-bottom: 50px;
  overflow: hidden;
}

.employee-profile-container--inner {
  max-width: 1260px;
  margin: auto;
  overflow: hidden;
}

.employee-profile--top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
}

.credit-new-wallet-btn-wrapper button {
  width: 250px;
}

/* Media queries */
@media (max-width: 1320px) {
  .employee-profile-container--inner {
    padding: 0 25px;
  }
}
