.employer-dashboard-container {
  padding-bottom: 30px;
}

.employer-dashboard-container--inner {
  position: relative;
  max-width: 1260px;
  margin: auto;
  overflow: hidden;
}

/* Employer dashboard ROW ONE SECTION */
section.employer-container-row--one {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 40px;
  /* justify-content: space-between; */
}

.row-one--left-container,
.row-one--middle-container,
.row-one--right-container {
  min-height: 300px;
  border: 1px solid #ede9f7;
  border-radius: 20px;
  padding: 15px 25px 0px;
}

.row-one--left-container {
  max-width: 600px;
  width: 100%;
}

.row-one--middle-container,
.row-one--right-container {
  width: 400px;
}

.row-one--middle-container {
  margin: 0 10px;
}

/* Row one Left, Middddle and Right Container */
.row-one--left--top-wrapper,
.row-one--left--middle-wrapper,
.row-one--middle--top-wrapper,
.row-one--right--top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-one--right--bottom-wrapper,
.row-one--middle--bottom-wrapper {
  display: flex;
  justify-content: space-between;
}

.row-one--box-title {
  font-size: 14px;
  font-family: "moderat-semibold";
  line-height: 12px;
  text-transform: uppercase;
  color: #0d0520;
}

.total-debt-sort-dropdown-wrapper,
.product-usage-sort-dropdown-wrapper,
.all-requests-sort-dropdown-wrapper {
  position: relative;
}

.all-requests-sort-dropdown-wrapper .select-dropdown-wrapper,
.total-debt-sort-dropdown-wrapper .select-dropdown-wrapper,
.product-usage-sort-dropdown-wrapper .select-dropdown-wrapper {
  background-color: #f8f8fa;
}

.total-debt-sort-dropdown-wrapper select,
.product-usage-sort-dropdown-wrapper select,
.all-requests-sort-dropdown-wrapper select {
  width: 110px;
  height: 33.33px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  background-color: #f8f8fa;
  border-radius: 10px;
  border: none;
  outline: none;
  box-sizing: border-box;
  font-style: normal;
  /* font-weight: 500; */
  font-size: 13px;
  line-height: 12px;
  color: #dadada;
}

.total-debt-sort-dropdown-wrapper select option,
.product-usage-sort-dropdown-wrapper,
select option,
.all-requests-sort-dropdown-wrapper select option {
  font-size: 14px;
  color: #0d0520;
}

.total-debt-sort-dropdown-wrapper .dropdown-icon img,
.product-usage-sort-dropdown-wrapper .dropdown-icon img,
.all-requests-sort-dropdown-wrapper .dropdown-icon img {
  width: 19.85px;
  height: 17.17px;
  position: absolute;
  height: 24px;
  top: 6px;
  right: 5px;
  filter: invert(1);
}

/* row-one--left, middle and right --middle-wrapper */
.row-one--left--middle-wrapper,
.row-one--middle--bottom-wrapper,
.row-one--right--bottom-wrapper {
  margin-top: 30px;
}

/* total-debt-value-wrapper && sales-level-percentage-wrapper */
.total-debt-percentage-wrapper,
.product-usage-percentage-wrappe {
  font-family: "moderat-medium";
  font-size: 36px;
  line-height: 38px;
  color: #3a0ca3;
}

.row--one-percent-change-wrapper {
  width: 75px;
  height: 39px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;

  /* identical to box height, or 117% */
}

.percent-increase-wrapper {
  background-color: rgba(0, 186, 7, 0.1);
  color: #00ba07;
}

.percent-decrease-wrapper {
  background-color: rgba(226, 49, 49, 0.1);
  color: #e23131;
}

.row--one-percent-change-wrapper img {
  transform: translateY(1px);
  padding-left: 5px;
}

.row-one--left--bottom-wrapper {
  margin-top: 25px;
}

.recent-payouts-title-wrapper {
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: #a9a9bc;
}

/* recent-payouts-values-container */
.recent-payouts-values-container {
  margin-top: 10px;
}

.recent-payouts-value-wrapper {
  width: 100%;
  max-width: 374px;
  height: 50px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #1a0404;
}

/* Employer dashboard ROW TWO SECTION && PART OF Employer dashboard ROW THREE SECTION  */
section.employer-container-row--two,
section.employer-container-row--three {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

/* chart-container */
.chart-container {
  margin-top: 10px;
  width: 185.37px;
  height: 185.37px;
  font-weight: 500;
  font-size: 13px;
  line-height: 10px;
  color: #a9a9bc;
}

.chart-container--inner {
  width: 155px;
  height: 155px;
  border-radius: 50%;
  background-color: #3a0ca3;
}

.requests-chart-container {
  padding: 8px;
  border: 5px solid #b5179e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-key-color {
  width: 21px;
  height: 10px;
  border-radius: 3px;
}

.staff-using-product-key,
.declined-requests-product-key {
  background-color: #ff3a29;
}

.staff-amount-product-key,
.approved-requests-product-key {
  background-color: #3a0ca3;
}

.chart-key-title {
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  color: #a9a9bc;
  margin: 8px 0;
}

.chart-key-value {
  font-weight: 500;
  font-size: 36px;
  line-height: 38px;
  color: #2e2e2e;
  margin-top: 2px;
}

.second-chart-key-wrapper {
  margin-top: 30px;
}

/* Employer dashboard ROW TWO SECTION */
.row-two-main-container {
  width: 610px;
  min-height: 400px;
  border: 1px solid #ede9f7;
  border-radius: 20px;
  padding: 15px 25px 0px;
}

.row-two--left--top-wrapper,
.row-two--right--top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-two--box-title {
  font-size: 14px;
  font-family: "moderat-semibold";
  line-height: 12px;
  text-transform: uppercase;
  color: #0d0520;
}

.disbursements-sort-dropdown-wrapper,
.dept-with-most-requests-sort-dropdown-wrapper {
  position: relative;
}

.disbursements-sort-dropdown-wrapper select,
.dept-with-most-requests-sort-dropdown-wrapper select {
  width: 110px;
  height: 33.33px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  background-color: #f8f8fa;
  border-radius: 10px;
  border: none;
  outline: none;
  box-sizing: border-box;
  font-style: normal;
  /* font-weight: 500; */
  font-size: 13px;
  line-height: 12px;
  color: #dadada;
}

.disbursements-sort-dropdown-wrapper select option,
.dept-with-most-requests-sort-dropdown-wrapper select option {
  font-size: 14px;
  color: #0d0520;
}

.disbursements-sort-dropdown-wrapper .dropdown-icon img,
.dept-with-most-requests-sort-dropdown-wrapper .dropdown-icon img {
  width: 19.85px;
  height: 17.17px;
  position: absolute;
  height: 24px;
  top: 6px;
  right: 5px;
  filter: invert(1);
}

.disbursements-bar-chart-container {
  margin-top: 15px;
  width: 540px;
  height: 215.37px;
  font-weight: 500;
  font-size: 13px;
  line-height: 10px;
  color: #a9a9bc;
}

.recharts-default-tooltip,
.recharts-tooltip-wrapper {
  border: 1px solid rgba(0, 186, 7, 0.1);
}
.recharts-default-tooltip:focus,
.recharts-default-tooltip:hover {
  outline: none !important;
  border: none !important;
}

.row-two--left--bottom-wrapper,
.row-two--right--bottom-wrapper {
  margin-top: 25px;
}

.disbursements-values-display-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  margin-top: 8px;
}

.disbursements-amount-title {
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  color: #a9a9bc;
}

.disbursements-amount-value {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-top: 8px;
}

.disbursements-keys-display-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  margin-top: 10px;
}

.total-disbursements-amount-value {
  color: #b5179e;
}
.total-approved-amount-value {
  color: #ffb200;
}
.total-declined-amount-value {
  color: #ff4848;
}

.total-disbursements-keys-box {
  background-color: #b5179e;
}

.total-approved-keys-box {
  background-color: #ffb200;
}
.total-declined-keys-box {
  background-color: #ff4848;
}

.total-disbursements-keys-wrapper,
.total-approved-keys-wrapper,
.total-declined-keys-wrapper {
  display: flex;
  align-items: center;
}

.disbursements-keys-box {
  width: 21px;
  height: 10px;
  margin-right: 8px;
  border-radius: 3px;
}

.dept-with-most-requests-table-container {
}

.dept-with-most-requests-table {
  width: 100%;
  background-color: #ffffff;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  /* Remove table gaps and margins */
  border-spacing: 0px;
}

.dept-with-most-requests-table thead {
  background-color: #f0ecf8;
}
/* Table Head , Table Row */
.dept-with-most-requests-table thead tr {
  border-radius: 10px;
}

/* Table Head , Table Row, Thable header*/
.dept-with-most-requests-table thead tr th {
  padding: 14px 0px;
  font-family: "moderat-semibold";
  font-size: 12px;
  line-height: 12px;
  color: #3a0ca3;
  padding-left: 25px;
}

/* Table Body */
.dept-with-most-requests-table tbody {
  transform: translateY(-5px);
  margin: 0;
}

/* Table Body , Table Data*/
.dept-with-most-requests-table tbody tr td {
  background-color: #f8f8fa;
  padding: 20px 0px;
  font-family: "moderat-medium";
  color: #2e2e2e;
  font-size: 15px;
  padding-left: 25px;
}

.dept-with-most-requests-table tbody tr td:nth-child(1) {
  border-radius: 10px 0 0 10px;
}

.dept-with-most-requests-table tbody tr td:nth-last-child(1) {
  border-radius: 0 10px 10px 0;
}

/* Media Queries */
@media (min-width: 1400px) {
  .employer-dashboard-container--inner {
    max-width: 1460px;
    margin: auto;
    /* padding: 0 30px; */
  }

  /* .row-one--left-container, */
  .row-one--middle-container,
  .row-one--right-container {
    width: 450px;
    height: 150px;
  }

  .row-one--left-container {
    max-width: 965px;
    width: 100%;
  }

  .row-two-main-container {
    width: 643px;
    min-height: 380px;
    border: 1px solid #ede9f7;
    border-radius: 20px;
  }
}
