.form-group textarea {
  padding: 15px;
  border: 1px solid #c0bace;
  box-sizing: border-box;
  border-radius: 10px;
  /* text styles  */
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  color: #1a0404;
}

.form-group .resize-textarea {
  resize: none;
}

.form-group textarea::placeholder {
  color: #a9a9bc;
  font-weight: 400;
}

.form-group textarea:disabled {
  background-color: inherit;
}
