.modal-container {
  position: absolute;
  width: 318px;
  min-height: 319px;
  z-index: 50;
  left: 40%;
  top: 25%;
  background: #ffffff;
  border-radius: 20px;
}

.approve-request-modal-container {
  top: 10% !important;
}

.modal-container--inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 35px;
}

.modal-header-text {
  font-family: "moderat-semibold";
  font-size: 20px;
  line-height: 20px;
  color: #1a0404;
  margin-top: 20px;
}

.modal-sub-text {
  width: 248px;
  font-size: 12.5px;
  line-height: 15px;
  text-align: center;
  color: #a9a9bc;
  margin-top: 20px;
}

.modal-sub-text span {
  color: #3a0ca3;
}

.modal-action-button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.confirm-modal-action-button {
  display: flex;
  align-items: center;
}

.cancel-modal-button-wrapper button,
.confirm-modal-action-button button {
  border: none;
  outline: none;
  cursor: pointer;
}

.cancel-modal-button-wrapper button {
  background-color: inherit;
  font-family: "moderat-medium";
  font-size: 15px;
  line-height: 15px;
  color: #ff4848;
}

.confirm-modal-action-button button {
  max-width: 169px;
  min-width: 150px;
  width: 100%;
  height: 50px;
  background: #3a0ca3;
  border-radius: 10px;
  font-family: "moderat-medium";
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
  transition: all ease-in-out 0.2s;
}

.confirm-modal-action-button button:hover {
  background: #320a90;
}

.confirm-modal-button-loader-wrapper {
  margin-left: 8px;
}

/* Other Modal Classes */
.approve-request-modal-container--inner form .form-group:nth-child(1) {
  margin-top: 20px;
}

.modal--salary-and-group-form-wrapper .form-group {
  margin-top: 20px;
}

.modal--salary-and-group-form-wrapper .form-group input,
.modal--salary-and-group-form-wrapper .form-group select {
  width: 260px;
  height: 40px;
}

.modal--salary-and-group-form-wrapper .form-group .dropdown-icon img {
  top: 28px;
  right: 16px;
}

.approve-request-modal-container--inner form,
.approve-request-modal-container--inner form .form-group,
.approve-request-modal-container--inner form .form-group input {
  width: 100% !important;
}
