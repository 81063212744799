.employee-info-container {
  display: flex;
  justify-content: space-between;
  column-gap: 40px;
  margin-top: 24px;
}

.secondary--employee-details-container {
  max-width: 715px;
  width: 100%;
  min-height: 355px;
  background: #fff;
  border-radius: 5px;
  padding: 20px 40px;
}

.secondary--employee-details-container--inner {
  display: grid;
  grid-template-columns: repeat(auto-fit, 180px);
  column-gap: 40px;
  row-gap: 45px;
  margin-top: 16px;
}

.employee-detail-wrapper {
  width: 180px;
}

.secondary--employee-wallet-details-wrapper {
  width: 364px;
  min-height: 100px;
  height: max-content;
  background: #fff;
  border-radius: 5px;
  padding: 20px 32px;
}

.employee-wallet-detail-col {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-top: 16px;
}

.wallet-detail-row-wrapper {
  width: 100%;
  height: 53px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  background: #f8f8fa;
}

.wallet-detail-title {
  font-family: "moderat-medium";
  font-size: 24px;
  line-height: 29px;
  color: #2e2e2e;
}
