.onboard-successful-container {
  /* display: flex;
  justify-content: center; */
  padding-bottom: 60px;
  min-height: 100vh;
}

.onboard-successful-container--inner {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Main Header text wrapper  */
.onboard-successful-container--inner .container-main-header-text {
  max-width: 480px;
  text-align: center;
  font-family: "moderat-bold";
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  margin-top: 10px;
  /* Gradient */
  background: linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.add-to-group-form-wrapper {
  margin-top: 50px;
}

.add-to-group-form-wrapper form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.group-name-form-group select {
  width: 500px;
}

.add-employees-to-group-btn-wrapper {
  margin-top: 20px;
}

.add-employees-to-group-btn-wrapper button {
  justify-content: center;
}

.onboard-more-employees-button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 30px;
}

.onboard-more-employees-button-wrapper button {
  width: 230px;
  height: 50px;
  background-color: #3a0ca3;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  /* Text styles */
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
  margin-left: 10px;
}
