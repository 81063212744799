.billing-format-form-container {
  margin-top: 40px;
}

.billing-date-format-form-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 16px;
}

.billing-date-format-form-wrapper .form-group label {
  display: flex;
  align-items: center;
  font-family: "moderat-medium";
  line-height: 150%;
  color: #1a0404;
  column-gap: 12px;
}

.billing-date-format-form-wrapper .form-group label input {
  transform: scale(1.4);
}

.employees-no-form-group {
  margin-top: 40px;
  padding: 0.5px;
}

.employees-no-form-group input {
  width: 400px;
}
