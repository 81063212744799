.select-notification-viewers-form-container {
  margin-top: 40px;
}

.select-notification-viewers-form-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 16px;
}

.select-notification-viewers-form-wrapper .form-group label {
  display: flex;
  align-items: center;
  font-family: "moderat-medium";
  line-height: 150%;
  color: #1a0404;
  column-gap: 12px;
}

.select-notification-viewers-form-wrapper .form-group label input {
  transform: scale(1.4);
}
