.bulk-upload-container {
  padding-bottom: 30px;
  font-family: "moderat-regular";
  position: relative;
  overflow: hidden;
}

.bulk-upload-container--inner {
  position: relative;
  max-width: 900px;
  margin: auto;
  padding-top: 40px;
  min-height: 100vh;
}

section.bulk-upload-container-main-section {
  margin-top: 50px;
  margin-left: 80px;
  margin-right: 80px;
}

/* CVS / XLS Doc Form groups / File Input form group */
.cvs-xls-doc-form-group {
  margin-bottom: 30px;
  width: max-content;
}

.cvs-xls-doc-form-group .file-form-group--inner {
  width: 500px;
  height: 100px;
  border: 1px dashed #c0bace;
  border-radius: 10px;
}

.file-form-group--inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* File Input Wrapper */
.cvs-xls-doc-form-group .file-input-wrapper {
  min-width: 160px;
  max-width: 400px;
  min-height: 33px;
  position: relative;
  background-color: #ede9f7;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* word-wrap: break-word; */
}

/* File Input */
.file-form-group--inner .file-input-wrapper input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  outline: none;
}

/* File Input Title */
.file-form-group--inner .file-input-wrapper .file-input-title {
  height: 100%;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #2e2e2e;
  padding: 0 3px;
}

.file-form-group label {
  font-weight: 400;
  display: block;
  font-size: 14px;
  line-height: 14px;
  color: #2e2e2e;
  margin-bottom: 5px;
}

.file-form-group label span {
  font-family: "moderat-light";
  font-style: italic;
  font-size: 12px;
  line-height: 12px;
  color: #2e2e2e;
}

.cvs-file-format-download-link-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cvs-file-format-download-link-wrapper a {
  text-decoration: none;
  font-size: 15px;
  font-family: "moderat-medium";
  color: #3a0ca3;
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cvs-file-format-download-link-wrapper a img {
  height: 16px;
  margin-left: 5px;
  filter: invert(0.4);
}
