.onboarding-container {
  padding-bottom: 30px;
  font-family: "moderat-regular";
}

.onboarding-container--inner {
  position: relative;
  max-width: 1260px;
  margin: auto;
  overflow: hidden;
}

.onboarding-table-section {
  margin-top: 40px;
}

/* onboarding-table-filter-option-wrapper */
.onboarding-table-filter-option-wrapper {
  display: flex;
  align-items: center;
}

.onboarding-table-filter-row-title {
  font-family: "moderat-medium";
  /* font-size: 15px; */
  line-height: 15px;
  color: #a9a9bc;
  margin-right: 10px;
}

.onboarding-table-filter-option-wrapper .form-group {
  margin-left: 20px;
}

.onboarding-table-filter-option-wrapper .select-dropdown-wrapper {
  background-color: #fff;
}

.onboarding-table-filter-option-wrapper .form-group select {
  width: 150px;
  border: none;
  color: #dadada;
  background-color: transparent !important;
  position: relative;
  z-index: 4;
}

.onboarding-table-filter-option-wrapper .form-group:nth-child(2) select {
  width: 200px;
}

.onboarding-table-filter-option-wrapper select option {
  font-size: 14px;
  color: #0d0520;
  z-index: 1;
}

.onboarding-table-filter-option-wrapper .form-group .dropdown-icon img {
  top: 35%;
}

/* All Orders Table Container */
.all-onboarding-table-container {
  margin-top: 40px;
}

.all-onboarding-table {
  width: 100%;
  background-color: #f0ecf8;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  /* Remove table gaps and margins */
  border-spacing: 0px;
}

.all-onboarding-table thead {
  background-color: #f0ecf8;
}
/* Table Head , Table Row */
.all-onboarding-table thead tr {
  border-radius: 5px 5px 0px 0px;
}

/* Table Head , Table Row, Thable header*/
.all-onboarding-table thead tr th {
  padding: 20px 10px;
  font-family: "moderat-semibold";
  font-size: 14px;
  line-height: 12px;
  color: #3a0ca3;
}

.all-onboarding-table thead tr th:nth-child(1) {
  padding-left: 50px;
}

/* Table Body */
.all-onboarding-table tbody {
  margin: 0;
}

.all-onboarding-table tbody tr {
  overflow: hidden;
}

.all-onboarding-table tbody tr:hover td {
  background-color: #f8f8fa;
}

/* Table Body , Table Data*/
.all-onboarding-table tbody tr td {
  background-color: #ffffff;
  padding: 16px 10px;
  font-family: "moderat-medium";
  color: #2e2e2e;
  font-size: 15px;
  border-bottom: 1px solid #f3f3f3 !important;
}

.all-onboarding-table tbody tr td:nth-child(1) {
  padding-left: 45px;
}

/*  Name Wrapper */
.all-onboarding-table .td-employee-name--wrapper {
  display: flex;
  align-items: center;
  max-width: 300px;
}

.all-onboarding-table .td-employee-name--wrapper .profile-image-wrapper {
  overflow: hidden;
  width: 40px;
  height: 40px;
  /* object-fit: cover; */
  border-radius: 4px;
  margin-right: 15px;
}

.all-onboarding-table .td-employee-name--wrapper .profile-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.td-decline-request--wrapper button,
.td-approve-request--wrapper button {
  width: 152px;
  height: 26px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
}

.td-decline-request--wrapper button img,
.td-approve-request--wrapper button img {
  margin-right: 5px;
}

.td-decline-request--wrapper button {
  background: rgba(206, 46, 46, 0.1);
  color: #ce2e2e;
}

.td-approve-request--wrapper button {
  background: rgba(0, 186, 7, 0.1);
  color: #00ba07;
}

/* Media queeries */
@media (max-width: 1320px) {
  .onboarding-container--inner {
    padding: 0 25px;
  }
}
